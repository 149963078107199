import { dropShadowLowStyle, spacingFluidMedium, spacingFluidXSmall } from "@porsche-design-system/components-js/styles";
import { UserRound } from "lucide-react";
import useTexts from "mainApp/assets/utils/hooks/useTexts";
import { tileBaseStyleDrop } from "mainApp/assets/visual/styles/globalStyles/globalJsxStyles";
import { ReactComponent as TuktukSVG } from '../../mainApp/assets/visual/vectors/UI/logos/Tuktuk.svg';
import { ReactComponent as UserSVG } from '../../mainApp/assets/visual/vectors/UI/logos/User.svg';
import React from "react";
import './VitalInfoComponent.css';
import useIsPhone from "mainApp/assets/utils/hooks/useIsPhone";
import { PText } from "@porsche-design-system/components-react";

const VitalInfoComponent = ({ tour, isMulti, className }) => {
    const { texts } = useTexts('tourInfo');
    const isPhone = useIsPhone();

    const vitalInfoStyling = {
        height: isPhone ? "100%" : "",
        minHeight: "7vh",
        // minHeight: isPhone ? "7vh" : "",
        padding: `0  ${spacingFluidXSmall}  ${spacingFluidXSmall} ${spacingFluidXSmall}`

    };
    const informationPersonsContainerStyling = {
        minHeight: isPhone ? "100%" : "",
    };

    return (

        <div className="vitalInfo"
            style={vitalInfoStyling}
        >
            <div
                style=
                {
                    {
                        minHeight: "7vh",
                        maxHeight: "7vh",
                        ...tileBaseStyleDrop,
                        ...dropShadowLowStyle,
                        padding: spacingFluidXSmall,
                    }
                }
            >
                <PText
                    style={{
                        marginBottom: spacingFluidXSmall
                    }}
                    weight="bold"
                >
                    {texts?.price_text || "Loading..."}
                </PText>
                <PText
                    className="flex justify-center"
                    size="medium"
                >
                    {tour.price}€
                </PText>
            </div>

            <div
                className="testing"
                style=
                    {
                    {
                        minHeight: "7vh",
                        maxHeight: "7vh",

                        ...tileBaseStyleDrop,
                        ...dropShadowLowStyle,
                        padding: spacingFluidXSmall,
                    }
                }
            >
                <TuktukSVG className="tuktukSVG" />
                <PText>&nbsp;&nbsp;=&nbsp;</PText>
                <UserSVG className="personSVG" />
                <PText>4</PText>
            </div>
        </div>
    );
};

export default VitalInfoComponent;