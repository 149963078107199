import React, { memo } from 'react';
import { PTextFieldWrapper } from '@porsche-design-system/components-react';
import './PhoneNumberInput.css';
import { spacingFluidSmall } from '@porsche-design-system/components-js/styles';

const PhoneNumberInput = memo(({ phoneRef, countryCodeRef, texts, onPhoneChange, isPhone }) => {
  const MAX_TOTAL_DIGITS = 15; // Máximo de dígitos según E.164

  // Función para contar los dígitos (excluyendo el '+')
  const countDigits = (str) => {
    return (str.match(/\d/g) || []).length;
  };

  // // Función para combinar el prefijo y el número de teléfono
  // const combinePhone = (countryCode, phoneNumber) => {
  //   const trimmedCountryCode = countryCode.trim();
  //   const trimmedPhoneNumber = phoneNumber.trim();
  //   if (trimmedCountryCode && trimmedPhoneNumber) {
  //     return `${trimmedCountryCode} ${trimmedPhoneNumber}`;
  //   }
  //   return trimmedCountryCode || trimmedPhoneNumber;
  // };

  // Asegurar que el prefijo siempre comience con +
  const handleCountryCodeInput = (event) => {
    let value = event.target.value;

    // Si el valor no empieza con +, lo corregimos
    if (!value.startsWith('+')) {
      value = `+${value.replace(/\D/g, '')}`;
    } else {
      // Solo permitir números después del +
      value = `+${value.slice(1).replace(/\D/g, '')}`;
    }

    // Limitar la longitud máxima del prefijo
    if (value.length > 5) { // MAX_PREFIX_LENGTH = 5
      value = value.slice(0, 5);
    }

    event.target.value = value;

    // Obtener dígitos actuales
    const countryCodeDigits = countDigits(value);
    const phoneNumber = phoneRef.current ? phoneRef.current.value : '';
    const phoneDigits = countDigits(phoneNumber);

    // Calcular dígitos permitidos para el número de teléfono
    const allowedPhoneDigits = MAX_TOTAL_DIGITS - countryCodeDigits;

    // Si el número de teléfono excede el permitido, lo truncamos
    if (phoneDigits > allowedPhoneDigits) {
      const truncatedPhone = phoneNumber.slice(0, allowedPhoneDigits);
      phoneRef.current.value = truncatedPhone;
    }

    // Actualizar formData.phone en el padre
    if (onPhoneChange) {
      const updatedPhoneNumber = phoneRef.current ? phoneRef.current.value : '';
      onPhoneChange(value, updatedPhoneNumber);
    }
  };

  // Función para manejar la validación del número de teléfono (permitir solo números)
  const handlePhoneNumberInput = (event) => {
    let value = event.target.value;
    // Eliminar cualquier carácter que no sea número
    value = value.replace(/\D/g, '');

    // Obtener dígitos actuales
    const countryCode = countryCodeRef.current ? countryCodeRef.current.value : '';
    const countryCodeDigits = countDigits(countryCode);
    const allowedPhoneDigits = MAX_TOTAL_DIGITS - countryCodeDigits;

    // Limitar la longitud del número de teléfono
    if (value.length > allowedPhoneDigits) {
      value = value.slice(0, allowedPhoneDigits);
    }

    event.target.value = value;

    // Actualizar formData.phone en el padre
    if (onPhoneChange) {
      const updatedCountryCode = countryCodeRef.current ? countryCodeRef.current.value : '';
      onPhoneChange(updatedCountryCode, value);
    }
  };

  // Función para validar la entrada del número de teléfono
  const validatePhoneNumber = (event) => {
    const countryCode = countryCodeRef.current ? countryCodeRef.current.value : '';
    const phoneNumber = event.target.value;
    const combinedDigits = countDigits(countryCode) + countDigits(phoneNumber);

    if (isNaN(phoneNumber) || combinedDigits < 7 || combinedDigits > 15) {
      event.target.setCustomValidity("Por favor, ingrese un número de teléfono válido (7-15 dígitos en total).");
    } else {
      event.target.setCustomValidity("");
    }
  };

  return (
    <div className="flex flex-row"
    style={{gap: spacingFluidSmall}}
    >
      <PTextFieldWrapper
        label={`${texts?.ph?.prefix}:`}
        className="confirmationLabel"
        style={{ width: isPhone ? "25%" : "15%"}}
      >
        <input
          type="tel"
          placeholder={`${texts?.ph?.countryCode}`}
          ref={countryCodeRef}
          className="confirmationInput"
          required
          maxLength={5} // MAX_PREFIX_LENGTH = 5
          onInput={handleCountryCodeInput} // Asegurar que el valor tenga siempre el '+'
        />
      </PTextFieldWrapper>

      <PTextFieldWrapper
        label={`${texts?.head}:`}
        className="confirmationLabel"
        style={{ width: "90%" }}
      >
        <input
          type="tel"
          placeholder={`${texts?.ph?.phone}:`}
          ref={phoneRef}
          className="confirmationInput"
          required
          pattern="\d*"
          onInput={handlePhoneNumberInput} // Solo permitir números
          onBlur={validatePhoneNumber} // Validar al perder el foco
        />
      </PTextFieldWrapper>
    </div>
  );
});

export default PhoneNumberInput;
