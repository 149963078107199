import React, { useContext, useEffect, useState } from 'react';
import { PTextFieldWrapper, PSelectWrapper, PText, PFieldsetWrapper } from '@porsche-design-system/components-react';
import './PersonalInfoFields.css';
import countries from 'i18n-iso-countries';
import { LanguageContext } from 'mainApp/assets/utils/context/LanguageContext';
import { spacingFluidSmall, spacingFluidXSmall } from '@porsche-design-system/components-js/styles';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';

const loadAndRegisterLanguage = async (languageCode) => {
    try {
        const localeData = await import(`i18n-iso-countries/langs/${languageCode}.json`);
        countries.registerLocale(localeData);
    } catch (error) {
        console.warn(`No se encontró el archivo para el idioma '${languageCode}'. Usando 'en' por defecto.`);
        const localeData = await import('i18n-iso-countries/langs/en.json');
        countries.registerLocale(localeData);
    }
};

// Función para obtener los nombres de los países en un idioma específico
export const getCountryNames = async (languageCode) => {
    await loadAndRegisterLanguage(languageCode);

    return Object.entries(countries.getNames(languageCode, { select: 'official' }))
        .map(([code, name]) => ({
            value: code,
            label: name,
        }));
};

const PersonalInfoFields = ({
    formData,
    setFormData,
    texts,
    phoneRef,
    onPhoneChange,
    countryCodeRef,
    phoneTexts,
    textHeader,
    isPhone
}) => {
    const { language: languageCode } = useContext(LanguageContext);
    const [countryList, setCountryList] = useState([]);

    useEffect(() => {
        const fetchCountryNames = async () => {
            const countries = await getCountryNames(languageCode);
            setCountryList(countries);
        };

        fetchCountryNames();
    }, [languageCode]);

    // useEffect para concatenar firstName y lastName en el campo name
    // useEffect(() => {
    //     // if (formData.firstName || formData.lastName) {
    //         setFormData(prevFormData => ({
    //             ...prevFormData,
    //             name: `${formData.firstName || ''} 
    //             ${formData.lastName || ''}`.trim() // Concatenar con un espacio y remover espacios extra
    //         }));
    //     // }
    // }, [formData.firstName, formData.lastName]);


    const contentFirst = (
        <>
            <PTextFieldWrapper
                className='w-full'
                label={texts?.name}
            >
                <input
                    type="text"
                    value={formData.firstName || ''}
                    onInput={(e) => setFormData({ ...formData, firstName: e.target.value })}
                    // onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                    required
                />
            </PTextFieldWrapper>

            <PTextFieldWrapper
                className='w-full'
                label={texts?.surname}
            >
                <input
                    type="text"
                    value={formData.lastName || ''}
                    onInput={(e) => setFormData({ ...formData, lastName: e.target.value })}
                    // onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                    required
                />
            </PTextFieldWrapper></>
    );

    return (
        // <div className="personalInfoFields" style={{ gap: spacingFluidXSmall }}>
        <PFieldsetWrapper label={textHeader}>
            <div className='flex flex-col' style={{ gap: spacingFluidSmall }}>
                {!isPhone ? (
                    <div className="flex flex-row w-full" style={{ gap: spacingFluidSmall }}>
                        {contentFirst}
                    </div>) : (contentFirst)
                }
                <PTextFieldWrapper label={texts?.mail}>
                    <input
                        type="email"
                        value={formData.email || ''}
                        onInput={(e) => setFormData({ ...formData, email: e.target.value })}
                        // onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        required
                    />
                </PTextFieldWrapper>

                <PSelectWrapper
                    filter={true}
                    label={`${texts?.country?.head}`}
                    required
                >
                    <select
                        value={formData.country || ''}
                        // onInput={(e) => setFormData({ ...formData, country: e.target.value })}
                        onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                        required
                    >
                        {/* Opción vacía al inicio */}
                        <option value="" disabled>
                            {texts?.country?.ph || 'Select a country'}
                        </option>
                        {countryList.map(country => (
                            <option key={country.value} value={country.value}>
                                {country.label}
                            </option>
                        ))}
                    </select>
                </PSelectWrapper>

                <PhoneNumberInput
                    phoneRef={phoneRef}
                    countryCodeRef={countryCodeRef}
                    onPhoneChange={onPhoneChange}
                    texts={phoneTexts}
                    isPhone={isPhone}
                />
            </div>

        </PFieldsetWrapper>
    );
};

export default PersonalInfoFields;
