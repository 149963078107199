// ConfirmationScreen.js
import React, { useState, useRef, useContext, useEffect } from 'react';
import { PButtonPure, PDivider, PText, PSpinner, PInlineNotification } from '@porsche-design-system/components-react';
import useTexts from 'mainApp/assets/utils/hooks/useTexts';
import { AppointmentContext } from './ReservationManager';
import axiosInstance from '../../axiosConfig';
import { DateTime } from 'luxon';
import { loadStripe } from '@stripe/stripe-js';
import './ConfirmationScreen.css';
import PhoneNumberInput from './PhoneNumberInput/PhoneNumberInput';
import RenderStaticData from './RenderStaticData/RenderStaticData';
import TuktukModelsSection from './TuktukModelsSection/TuktukModelsSection';
import BlurFade from '@/components/magicui/blur-fade';
import {
  dropShadowLowStyle,
  spacingFluidLarge,
  spacingFluidMedium,
  spacingFluidSmall,
  spacingFluidXLarge,
  spacingFluidXSmall,
  textLargeStyle,
  textXLargeStyle
} from '@porsche-design-system/components-js/styles';
import RenderHumanDataFields from './RenderHumanDataFields/RenderHumanDataFields';
import PersonalInfoFields from './PersonalInfoFields/PersonalInfoFields';
import StickyDrawer from 'components/StickyDrawer/StickyDrawer';
import { tileBaseStyleDrop } from 'mainApp/assets/visual/styles/globalStyles/globalJsxStyles';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC}`);

const getCsrfToken = async () => {
  const response = await axiosInstance.get('/api/csrf-token');
  return response.data.csrf_token;
};

const ConfirmationScreen = ({ date, time, onBack, formData, setFormData, isPhone, tourDuration }) => {
  const { texts } = useTexts("confirmationScreen");
  const phoneRef = useRef(null);
  const countryCodeRef = useRef(null);

  // Estados
  const [hasMinors, setHasMinors] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [minors, setMinors] = useState([]);
  const [minorsTotal, setMinorsTotal] = useState(0);
  const [adults, setAdults] = useState(1);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [validationMessages, setValidationMessages] = useState({});
  // const [loadingModels, setLoadingModels] = useState(false);
  // const [modelsFetched, setModelsFetched] = useState(false);
  const [tuktukModels, setTuktukModels] = useState([]);
  const [errors, setErrors] = useState(false);

  function addMinutesToEpoch(minutes, epochTime) {
    const millisecondsToAdd = minutes * 60 * 1000;
    const newEpochTime = epochTime + millisecondsToAdd;
    setFormData(prevFormData => ({
      ...prevFormData,
      bookingEnd: newEpochTime,
    }));
  }
  useEffect(() => {
    setFormData(prevFormData => ({ ...prevFormData, hasMinors: hasMinors }));
  }, [hasMinors]);

  useEffect(() => {
    addMinutesToEpoch(tourDuration, formData.bookingStart);
  }, [formData.bookingStart]);

  useEffect(() => {
    const bookingStart = DateTime.fromISO(`${date}T${time}`, { zone: 'Europe/Madrid' }).toSeconds();
    setFormData(prevFormData => ({
      ...prevFormData,
      bookingStart,
    }));
  }, [date, time, setFormData]);

  // Actualizar total de personas
  useEffect(() => {
    const newPersons = adults + minorsTotal;
    setFormData(prevFormData => ({ ...prevFormData, adults: adults, persons: newPersons }));
  }, [adults, minorsTotal, setFormData]);

  // Manejar cita y pago
  const handleConfirmAndSetAppointment = () => {
    handleStripeCheckout();
  };

  const handlePhoneChange = (countryCode, phoneNumber) => {
    const combinedPhone = [countryCode, phoneNumber].filter(Boolean).join(' ');
    setFormData(prevFormData => ({
      ...prevFormData,
      phone: combinedPhone,
      countryCode,
    }));
  };

  const handleStripeCheckout = async () => {
    setIsProcessingPayment(true);
    const stripe = await stripePromise;
    const csrfToken = await getCsrfToken();

    //Concatenando previo a la request
    const fullName = `${formData.firstName || ''} ${formData.lastName || ''}`.trim();
    
    try {
      const response = await axiosInstance.post('/api/checkout', {
        name: fullName,
        // name: formData.name,
        email: formData.email,
        phone: formData.phone,
        country: formData.country,
        sprice: formData.priceUnit,
        totalUnits: formData.totalUnits,
        tuktukModels: formData.tuktukModels,
        origin_url: window.location.href,
        bookingEnd: formData.bookingEnd,
        bookingStart: formData.bookingStart,
        tourUUID: formData.tourUUID,
        customPickup: formData.customPickup,
        persons: formData.persons,
        adults: formData.adults,
        minorsTotal: formData.minorsTotal,
        minors: formData.minors,
        hasMinors: formData.hasMinors,
        minors2to5: formData.minors2to5,
        minors6to10: formData.minors6to10,
        minors11to17: formData.minors11to17,
      }, {
        headers: { 'X-CSRF-TOKEN': csrfToken },
      });

      const session = response.data;
      const result = await stripe.redirectToCheckout({ sessionId: session.id });
      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      if (error.response && error.response.data && error.response.data.errors) {
        setValidationMessages(error.response.data.errors);
      } else if (error.response && error.response.data && error.response.data.error) {
        setValidationMessages({ apiError: error.response.data.error });
      } else {
        setValidationMessages({ apiError: 'An unexpected error occurred' });
      }
    } finally {
      setIsProcessingPayment(false);
    }
  };

  const addTuktukModel = () => {
    const newTuktukModels = [...formData.tuktukModels, { value: '', units: 1 }];
    setFormData({ ...formData, tuktukModels: newTuktukModels });
  };

  const removeTuktukModel = (index) => {
    const newModels = formData.tuktukModels.filter((_, i) => i !== index);
    setFormData({ ...formData, tuktukModels: newModels });
  };

  // Calcular precio total
  const calculateTotalPrice = () => {
    if (formData.priceUnit && formData.tuktukModels) {
      const totalUnits = formData.tuktukModels.reduce((acc, model) => acc + model.units, 0);
      setTotalPrice(formData.priceUnit * totalUnits);
    }
  };

  // Cargar modelos de tuktuk
  useEffect(() => {
    const fetchTuktukModels = async (tourUUID, bookingStart) => {
      // setLoadingModels(true);
      // setModelsFetched(false);
      try {
        const params = { tourUUID, bookingStart };
        const response = await axiosInstance.get(`/api/tuktuksAPI/get-simple`, { params });
        if (Array.isArray(response.data)) {
          const models = response.data.map(tuktuk => ({
            value: tuktuk.tuktukUUID,
            label: tuktuk.model,
            availableUnits: tuktuk.units,
            maxPeople: tuktuk.maxPeople,
          }));
          setTuktukModels([{ value: '', label: 'Seleccionar un modelo' }, ...models]);
          // setModelsFetched(true);
          setFormData(prevFormData => ({ ...prevFormData, tuktukModels: [{ value: '', units: 1 }] }));
        }
      } catch (error) {
        console.error('Error fetching tuktuk models:', error);
      } finally {
        // setLoadingModels(false);
      }
    };

    if (formData.tourUUID && formData.bookingStart) {
      fetchTuktukModels(formData.tourUUID, formData.bookingStart);
    }
  }, [formData.tourUUID, formData.bookingStart, setFormData]);

  useEffect(() => {
    calculateTotalPrice();
  }, [formData.tuktukModels, formData.priceUnit]);

  // Verificar si el formulario está incompleto
  const isFormIncomplete = () => {
    if (!formData.firstName || !formData.lastName || !formData.email || !formData.phone || !formData.country) {
      console.log("First: Fail");
      return true;
    }
    else {
      console.log("First: Ok");
    }
    if (!formData.persons || formData.persons <= 0) {
      console.log("Second: Fail");
      return true;
    } else {
      console.log("Second: Ok");
    }
    if (formData.tuktukModels.some(model => !model.value || model.units <= 0 || errors)) {
      console.log("Third: Fail");
      return true;
    }
    else {
      console.log("Third: Ok");
    }
    if (!tuktukModels || tuktukModels.length === 0) {
      console.log("Fourth: Fail");
      return true;
    } else {
      console.log("Fourth: Ok");
    }

    // Calcular capacidad total
    const totalCapacity = formData.tuktukModels.reduce((acc, model) => {
      const tuktuk = tuktukModels.find(t => t.value === model.value);
      return acc + (tuktuk ? tuktuk.maxPeople * model.units : 0);
    }, 0);

    if (totalCapacity < formData.persons) return true;
    return false;
  };

  const conditionalContainerStyling = {
    display: isPhone ? "flex" : "grid",
  };

  // Agregar menor
  const handleAddMinor = () => {
    setMinors([...minors, { age: 2 }]);
    setMinorsTotal(prevTotal => prevTotal + 1);
    setHasMinors(true);

    setFormData((prevFormData) => ({
      ...prevFormData,
      minors: true,
      minorsTotal: minorsTotal + 1,
      minors2to5: formData.minors2to5 + 1,
    }));
  };

  const handleRemoveMinor = (index) => {
    const updatedMinors = minors.filter((_, i) => i !== index);
    setMinors(updatedMinors);
    setMinorsTotal(updatedMinors.length);
    const categories = updateMinorCategories(updatedMinors);
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...categories,
      minorsTotal: updatedMinors.length,
      minors: updatedMinors.length > 0,
    }));

    setValidationMessages((prev) => {
      const updatedMessages = { ...prev };
      delete updatedMessages[`minor-${index}`];
      return updatedMessages;
    });
  };

  const updateMinorCategories = (minorsList) => {
    let minors2to5 = 0;
    let minors6to10 = 0;
    let minors11to17 = 0;

    minorsList.forEach((minor) => {
      const age = parseInt(minor.age, 10);
      if (age >= 2 && age <= 5) {
        minors2to5 += 1;
      } else if (age >= 6 && age <= 10) {
        minors6to10 += 1;
      } else if (age >= 11 && age <= 17) {
        minors11to17 += 1;
      }
    });

    return { minors2to5, minors6to10, minors11to17 };
  };

  const handleMinorAgeChange = (index, value) => {
    const age = parseInt(value, 10);

    if ((age >= 2 && age <= 17) || value === '') {
      const updatedMinors = [...minors];
      updatedMinors[index].age = value;
      setMinors(updatedMinors);

      const categories = updateMinorCategories(updatedMinors);
      setFormData((prevFormData) => ({
        ...prevFormData,
        minorsTotal: updatedMinors.length,
        minors2to5: categories.minors2to5,
        minors6to10: categories.minors6to10,
        minors11to17: categories.minors11to17,
      }));

      setValidationMessages((prev) => {
        const updatedMessages = { ...prev };
        delete updatedMessages[`minor-${index}`];
        return updatedMessages;
      });
    } else {
      setValidationMessages((prev) => ({
        ...prev,
        [`minor-${index}`]: 'La edad debe estar entre 2 y 17 años.'
      }));
    }
  };

  // Sincronizar menores con formData
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      minors: minors.map(minor => ({ age: minor.age })),
      minorsTotal: minors.length,
    }));
  }, [minors, setFormData]);

  return (
    <>
      {/* <BlurFade className="w-full"> */}
      <div className="confirmationScreen">

        <PButtonPure className="confirmationBackButton" icon="arrow-left" hideLabel={true} onClick={onBack}
          style={{ marginLeft: isPhone ? "" : spacingFluidXSmall }}
        />
        <div className="conditionalContainer" style={conditionalContainerStyling}>

          {/* Sección dinámica */}
          <div className="leftConfirmationScreen"
            style={{
              padding: isPhone ? spacingFluidSmall : `0 ${spacingFluidLarge} ${spacingFluidXLarge} ${spacingFluidXLarge}`,
              margin: isPhone ? `0 ${spacingFluidSmall} ${spacingFluidLarge} ${spacingFluidSmall}` : "",
              gap: isPhone ? spacingFluidLarge : spacingFluidMedium
            }}
          >
            {!isPhone && (
              <PDivider direction="vertical" className="vDivider" />
            )}

            <BlurFade
              className="z-0"
            >

              <PText
                size="x-large"
                weight={isPhone ? "bold" : ''}
                style={{ marginBottom: spacingFluidMedium }}
              >
                {texts?.form?.headers?.bookData}
              </PText>

              <RenderHumanDataFields
                adults={adults}
                setAdults={setAdults}
                handleMinorAgeChange={handleMinorAgeChange}
                handleRemoveMinor={handleRemoveMinor}
                minors={minors}
                setMinors={setMinors}
                texts={texts?.form?.humanData}
                handleAddMinor={handleAddMinor}
                headerText={texts?.form?.headers?.passengers}
              />
            </BlurFade>
            <BlurFade
              className="relative"
            >
              <div
                className='flex flex-col'
                style={
                  {
                    gap: isPhone ? spacingFluidLarge : spacingFluidMedium
                  }
                }
              >
                <TuktukModelsSection
                  addTuktukModel={addTuktukModel}
                  removeTuktukModel={removeTuktukModel}
                  formData={formData}
                  setFormData={setFormData}
                  tuktukModels={tuktukModels}
                  adults={adults}
                  persons={formData.persons}
                  validationMessages={validationMessages}
                  texts={texts?.form?.modelData}
                  textsExtra={texts?.form?.headers?.extra}
                  textsHeader={texts?.form?.headers}
                  setErrors={setErrors}
                />


                <PersonalInfoFields formData={formData} setFormData={setFormData} texts={texts?.form?.personalData}
                  phoneRef={phoneRef} countryCodeRef={countryCodeRef}
                  onPhoneChange={handlePhoneChange}
                  phoneTexts={texts?.form?.personalData?.phone}
                  textHeader={texts?.form?.headers?.personal}
                  isPhone={isPhone}
                />
              </div>
            </BlurFade>

          </div>

          {/* Sección estática */}
          {!isPhone && (
            <BlurFade>

              <div className="rightConfirmationScreen"
                style={{ padding: spacingFluidSmall }}
              >
                <RenderStaticData
                  titleStyle={textXLargeStyle}
                  time={time}
                  date={date}
                  totalPrice={totalPrice}
                  formData={formData}
                  isFormIncompleteProp={isFormIncomplete()}
                  handleConfirmAndSetAppointment={handleConfirmAndSetAppointment}
                  texts={texts}
                  currency="€"
                />
              </div>
            </BlurFade>

          )}
        </div>

        {validationMessages && (
          <div className="error-messages">
            {Object.entries(validationMessages).map(([field, messages]) => (
              <PInlineNotification
                key={field}
              >
                {texts?.paymentIntent?.error}
              </PInlineNotification>
            ))}
          </div>
        )}
      </div>
      {isPhone &&
        (
          <StickyDrawer
            price={totalPrice}
            currency="€"
            isProcessingPayment={isProcessingPayment}
            spinnerText={texts?.paymentIntent?.processing}
          >
            <RenderStaticData
              titleStyle={textXLargeStyle}
              time={time}
              date={date}
              totalPrice={totalPrice}
              formData={formData}
              isFormIncompleteProp={isFormIncomplete()}
              handleConfirmAndSetAppointment={handleConfirmAndSetAppointment}
              texts={texts}
              currency="€"
              isPhone={isPhone}
            />
          </StickyDrawer>
        )}
      {isProcessingPayment && (
        <div className="spinnerOverlay">
          <div className="spinnerContainer"
            style={{
              ...tileBaseStyleDrop,
              ...dropShadowLowStyle,
              // backgroundColor: "white",
              padding: spacingFluidMedium

            }}
          >
            <PSpinner />
            <PText>{texts?.paymentIntent?.processing}</PText>
          </div>
        </div>
      )}
    </>
    // </BlurFade >
  );
};

export default ConfirmationScreen;
